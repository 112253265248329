@use '@on3/styles/base';

@use '@on3/styles/mixins' as mix;

@use '@on3/styles/variables' as var;

.joinBlock {
  background-color: rgba(var.$newGreen, 0.08);
  padding: var.$spacing-regular;
  margin-top: 10px; // We should do this with the layout
  color: var.$gray;
  font-size: 0.75rem;
  letter-spacing: 0.35px;

  @include mix.flex(
    $justify: 'center',
    $gap: var.$spacing-compact,
    $wrap: 'wrap'
  );

  &:not(a) {
    .joinButton {
      cursor: auto;
    }
  }

  > div {
    @include mix.flex(
      $justify: 'center',
      $gap: var.$spacing-compact,
      $wrap: 'wrap'
    );
  }

  .joinButton {
    @extend %btn-subscribe;

    line-height: 0.75rem;
    font-size: 0.6rem;
  }

  .joinLink {
    font-weight: 600;
    color: var.$positiveDay;
  }
}
