@use '@on3/styles/variables' as var;

.sidebar {
  display: none;

  @media screen and (min-width: var.$sidebar-break) {
    display: block;
  }

  .trendingWrapper {
    padding: var.$spacing-regular 0;
  }
}

.pianoBanner {
  min-height: 44px;
  width: 100%;
  margin: 0 0 1rem;

  @media screen and (min-width: var.$sidebar-break) {
    margin: 0 0 var.$spacing-regular;
  }
}

@media screen and (min-width: var.$sidebar-break) {
  .articleWrapper {
    max-width: 810px;

    &.noAccess {
      grid-column: 1/3;
      margin: 0 auto;
    }
  }
}
